'use client'
// import Loader from 'components/loader'
// import PageLayout from 'components/page-layout'
// import {
//   AccessManagement,
//   DeviceManagement,
//   HybridWorksCarousel,
//   ITManagement,
//   IdentityManagement,
//   ProductVideo,
// } from 'page-components/home-page'
// import Hero from 'page-components/home-page/Hero'
// import Client from 'page-components/home-page/client'
// import React, {Suspense} from 'react'

// export default function Page() {
//   return (
//     <PageLayout showExtraSection={true}>
//       <Hero />
//       <Client />
//       <Suspense fallback={<Loader />}>
//         <ProductVideo />
//         <ITManagement />
//         <IdentityManagement />
//         <AccessManagement />
//         <DeviceManagement />
//         <HybridWorksCarousel />
//       </Suspense>
//     </PageLayout>
//   )
// }

import React, {Suspense} from 'react'
import Loader from 'components/loader'
import PageLayout from 'v1/common-components/page-layout/PageLayout'
import ProductModule from 'v1/page-components/home-page/product-module/ProductModule'
import Navigation from 'v1/common-components/navigation/Navigation'
import AllInOneSolution from 'v1/page-components/home-page/all-in-one-solution/AllInOneSolution'
import Hero from 'v1/page-components/home-page/hero/Hero'

export default function Page() {
  return (
    <PageLayout>
      <Navigation />
      <Hero />
      <Suspense fallback={<Loader />}>
        <AllInOneSolution />
        <ProductModule />
      </Suspense>
    </PageLayout>
  )
}
