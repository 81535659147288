import {useState} from 'react'
import Image from 'next/image'
import clsx from 'clsx'
import Button from 'v1/common-components/button/Button'
import IconsMarquee from 'v1/common-components/icons-marquee/IconsMarquee'
import {heroData, heroMarqueeImages} from 'v1/content/home-page'
import Modal from 'v1/common-components/modal/Modal'
import BookDemoForm from 'v1/page-components/marketing/common/demo-form/BookDemoForm'

import ArrowRightIcon from 'v1/assets/icons/arrow-right.svg'
import classes from './styles.module.css'

interface DemoData {
  isFreeTrialPage?: boolean
  isNameRequired?: boolean
  heading?: string
  buttonText?: string
  isTermAndCondition?: boolean
  thankYouTitle?: string
  thankYouParagraph?: string
}

const defaultDemoData = {
  isFreeTrialPage: true,
  isNameRequired: true,
  heading: 'Get ZenAdmin for Free',
  buttonText: 'Submit',
  isTermAndCondition: true,
  thankYouTitle: 'Your Account Has Been Created',
  thankYouParagraph:
    'Please check your email for login details to access your ZenAdmin account. Meanwhile, you can schedule a convenient time for a personalized demo.',
}

export default function Hero() {
  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [demoData, setDemoData] = useState<DemoData>({...defaultDemoData})

  const toggleModal = (data: DemoData | null = null) => {
    setDemoData(
      data || {
        ...defaultDemoData,
      },
    )
    setModalOpen(prev => !prev)
  }

  return (
    <>
      <section className={classes.heroSection}>
        <div className={classes.innerHeroSection}>
          <div className={classes.heroTopSection}>
            <div className={classes.topInnerSection}>
              <h1 className="h1-v1">{heroData?.heading}</h1>
              <p className="paragraph1-v1">{heroData?.subHeading}</p>
            </div>
            <div className={classes.ctaContainer}>
              <Button
                text="Get ZenAdmin For free"
                onClick={() => toggleModal()}
                style={{gap: '12px', borderRadius: '16px', padding: '12px 24px'}}
                iconRight={
                  <span className={classes.btnIconBox}>
                    <Image src={ArrowRightIcon} alt="login-icon" width={24} height={24} />
                  </span>
                }
                isAnimate={true}
              />
              <Button
                variant="custom"
                text="Book a Demo"
                onClick={() => toggleModal({})}
                style={{gap: '12px', borderRadius: '16px'}}
                className={clsx(classes.bookDemoBtn)}
              />
            </div>
          </div>
          <video autoPlay muted loop playsInline className={classes.heroVideo}>
            <source src="/assets/hero-video.webm" type="video/webm" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div>
          <IconsMarquee
            animate={{x: ['0%', '-50%']}}
            list={[...heroMarqueeImages, ...heroMarqueeImages]}
            iconBoxClass={classes.marqueeLogoBox}
            customMarqueeContainer={classes.heroMarqueeContainer}
          />
        </div>
      </section>
      <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
        <BookDemoForm data={demoData} />
      </Modal>
    </>
  )
}
